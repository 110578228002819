import React from 'react';

import Spinner from 'react-spinkit';

export default () =>
  <div className="row pt4 pb4">
    <div className="col-xs-5 col-sm-5 col-md-2 col-lg-6"></div>
    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
      <Spinner name="line-scale" />
    </div>
  </div>