import React from 'react';
import { Container } from 'constate';

import Layout from '../../components/layout';
import Busy from '../../components/widgets/busy';

import { authEffects } from '../../app/effects/auth';

const destination = '/app/dashboard';

const AuthCallback = (props) => (
  <Container
    context='primary'
    effects={authEffects}>
    {({ handleAuth }) => {
      //console.log('re-render callback');
      handleAuth(props, destination);
      return (
        <Layout>
          <Busy/>
        </Layout>
      )
    }}
  </Container>
);

export default AuthCallback;